import React, { useEffect, useState } from 'react';

const AnimatedStrokeOval = ({ xOffset = 1.259, yOffset = 10 }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // params
  const centerX = dimensions.width / xOffset;
  const centerY = dimensions.height / yOffset;
  const rx = dimensions.width * 0.5;
  const ry = dimensions.height * 0.7;
  // circumference
  const circumference = 2 * Math.PI * Math.sqrt((rx * rx + ry * ry) / 2);

  return (
    <div className="animated-oval-container">
      <svg 
        className="animated-oval-svg" 
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <ellipse
          className="animated-oval-path"
          cx={centerX}
          cy={centerY}
          rx={rx}
          ry={ry}
          fill="none"
          stroke="rgba(156, 163, 175, 0.3)"
          strokeWidth="2"
        />
      </svg>
      <style>{`
        .animated-oval-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          pointer-events: none;
          z-index: -50;
        }

        .animated-oval-svg {
          width: 100%;
          height: 100%;
          opacity: 80%;
        }

        .animated-oval-path {
          stroke-dasharray: ${circumference};
          stroke-dashoffset: ${circumference};
          animation: drawOval 12s ease-out forwards;
        }

        @keyframes drawOval {
          to {
            stroke-dashoffset: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default AnimatedStrokeOval;