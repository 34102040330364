import { useState, useEffect } from 'react';
import '../styles/AnimatedHero.css';

const AnimatedHero = () => {
  const roles = ["Graphic Designer", "Web Developer", "UI/UX Designer", "ML Enthusaist", "Technophile","Data Head", "Problem Solver"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    if (!isAnimating) return;
    
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % roles.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [isAnimating]);

  return (
    <div className="home-content">
      <div className="hero-section">
        <h1>
          Hello, I'm Callum,
          <br />
          a{' '}
          <span 
            className="role-wrapper"
            onMouseEnter={() => setIsAnimating(false)}
            onMouseLeave={() => setIsAnimating(true)}
          >
            <div className="slider-frame">
              <ul 
                className="slides-list"
                style={{
                  transform: `translateY(${-currentIndex * 4.2}rem)`
                }}
              >
                {roles.map((role, index) => (
                  <li key={index} className="slide-item">
                    {role}
                  </li>
                ))}
              </ul>
            </div>
          </span>
        </h1>
        <p>
          I focus on solving problems in the 
          <br />
          creative industry using innovative 
          <br />
          and impactful design solutions
        </p>
      </div>
    </div>
  );
};

export default AnimatedHero;