import React, { useState, useEffect } from 'react';
import { Link, useMatch, useResolvedPath, useNavigate, useLocation } from "react-router-dom";
import '../styles/Header.css';
import logo from '../assets/logo_callumdoty.png';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [experienceScrollPending, setExperienceScrollPending] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.nav-links') && !event.target.closest('.mobile-menu-btn')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  
  useEffect(() => {
    document.getElementById('header')?.scrollIntoView({ behavior: 'smooth' });
  }, [location]);


  useEffect(() => {
    if (experienceScrollPending) {
      document.getElementById('projects')?.scrollIntoView({ behavior: 'smooth' });
      setExperienceScrollPending(false);
    }
  }, [experienceScrollPending, location]);

  
  const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
      <li className={isActive ? "active" : ""}>
        <Link 
          to={to} 
          onClick={() => setIsMenuOpen(false)} 
          {...props}
        >
          {children}
        </Link>
      </li>
    );
  };

  const handleExperienceClick = (e) => {
    e.preventDefault();
    setIsMenuOpen(false);  
    
    if (location.pathname !== '/') {
      setExperienceScrollPending(true);
      navigate('/', { replace: true });
    } else {
      document.getElementById('projects')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="header" id="header">
      <div className="nav-container">
        {/* Logo */}
        <Link 
          to="/" 
          className="logo"
          onClick={() => setIsMenuOpen(false)}
        >
          <img 
            src={logo} 
            alt="Callum Doty Logo" 
            className="logo-img" 
          />
        </Link>

        {/* Mobile Menu Button */}
        <button 
          className={`mobile-menu-btn ${isMenuOpen ? 'open' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          aria-label="Toggle menu"
        >
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>

        {/* Navigation Links */}
        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <CustomLink to="/About">About</CustomLink>
          <li>
            <a 
              href="#projects" 
              onClick={handleExperienceClick}
              className={
                location.pathname === '/' && 
                document.getElementById('projects')?.getBoundingClientRect().top < window.innerHeight 
                  ? 'active' 
                  : ''
              }
            >
              Experience
            </a>
          </li>
          <CustomLink to="/Blog">Blog</CustomLink>
        </ul>
      </div>
    </nav>
  );
}